import 'htmx.org'
import 'flowbite'
import Alpine from 'alpinejs'

// Add Alpine instance to window object.
window.Alpine = Alpine;

// Start Alpine.
Alpine.start();

/* Custom scripts */
window.chatSubmit = function(e) {
	const question = document.querySelector("#question").value;

	// Question bubble by template
	const template = document.querySelector("#tpl-question-bubble");
	const clone = template.content.cloneNode(true);
	let now = new Date();
	clone.querySelector(".time").textContent = now.toLocaleString("de-de", {hour: "2-digit", minute: "2-digit"});
	clone.querySelector(".content").textContent = question;

	// Append the bubble
	const messages = document.getElementById("messages");
	messages.appendChild(clone);
	messages.parentElement.scrollTop = messages.parentElement.scrollHeight;
}